import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments } from "../../../../actions/accidentDashboard";
import { clearIdTurnos } from "../../../../actions/appointments";
import {
  correctConnectivityErrorAction,
  getAllConnectivityErrors,
  getConnectivityErrors,
} from "../../../../actions/connectivityResponse";
import { clearFormLocalidades, getLocalidades, postForm } from "../../../../actions/forms";
import { convertDateTimeToNet } from "../../../../utils/datetimeUtils";
import CircularLoading from "../../../commons/loadingData/circularLoading";
import Fpt from "./fpt";

const initialFptModel = {
  fechaHoraSolicitud: null,
  fechaHoraTurno: null,
  tipoTransporteNombre: "",
  tipoUrgenciaNombre: 1,
  solicitadoPor: null,
  direccionHabitualDesde: null,
  calleDesde: "",
  numeroDesde: "",
  pisoDesde: "",
  departamentoDesde: "",
  entreCalleDesde: "",
  entreCalleDesde1: "",
  localidadDesde: "Capital Federal",
  provinciaDesde: "Capital Federal",
  codigoPostalDesde: "",
  telefonosDesde: "",
  direccionHabitualHasta: null,
  calleHasta: "",
  numeroHasta: "",
  pisoHasta: "",
  departamentoHasta: "",
  entreCalleHasta: "",
  entreCalleHasta1: "",
  localidadHasta: "Capital Federal",
  provinciaHasta: "Capital Federal",
  codigoPostalHasta: "",
  telefonosHasta: "",
  observaciones: "",
  esperaRetorno: null,
  tipoDireccionDesde: null,
  tipoDireccionHasta: null,
};

const FptContainer = ({
  handleClose,
  handleOpenConfirmDialog,
  isView,
  setLoaded,
  idArt,
  siniestroProps,
  selectedDocument,
  fromErroresConectividad,
  fromErrorDialog,
}) => {
  const dispatch = useDispatch();

  const accident = useSelector((state) => state.accidentDashboard.accident);
  const saveForm = useSelector((state) => state.forms.saveForm);
  const postAppointment = useSelector(
    (state) => state.appointments.postAppointment,
  );
  const postSession = useSelector(
    (state) => state.appointments.postSession.data,
  );
  const appointmentState = useSelector(
    (state) => state.appointments.postSession.isFetching,
  );
  // Modelo traido de la api se usa combinado con FTP en caso !Isview
  const model = useSelector((state) => state.forms.model);
  // Datos de form a editar se usa si es IsView
  const form = useSelector((state) => state.forms.form);
  // Datos seteados en redux en general al salir de crear un turno con traslado = true. Ejemplo: IAMI, IET.
  const fpt = useSelector((state) => state.forms.fpt);
  const time = useSelector(
    (state) => state.connectivityResponse.connectivityErrors.time,
  );

  const localidadDesde = useSelector((state) => state.forms.localidades.desde);
  const localidadHasta = useSelector((state) => state.forms.localidades.hasta);

  const [files, setFiles] = useState([]);
  const [fptModel, setFptModel] = useState(null);
  const [siniestroState, setSiniestroState] = useState(null);

  // Fecha de turnos puede ser un array o fecha dependiendo del caso. (Ejemplo: insertar multiples sesiones da un rango de fechas).
  const [dateWSplit, setDateWSplit] = useState(null);
  const [idArtProps, setIdArtProps] = useState(null);
  const [loadingProvincias, setloadingProvincias] = useState(true);

  const isLoading =
    model.isFetching ||
    saveForm.isFetching ||
    form.isFetching ||
    !fptModel ||
    (fpt.isOpen && dateWSplit === null) ||
    (fpt.isOpen && fpt.isOutsideAccident && appointmentState)
    || (loadingProvincias && isView);


  useEffect(() => {
    if (isView && idArtProps) {
      // Buscar localidades en base a provincias 
      if (form?.data?.form?.provinciaDesde && form?.data?.form?.provinciaDesde != "Capital Federal") {
        dispatch(getLocalidades(idArtProps, form?.data?.form?.provinciaDesde, true));
      }
      if (form?.data?.form?.provinciaHasta && form?.data?.form?.provinciaHasta != "Capital Federal") {
        dispatch(getLocalidades(idArtProps, form?.data?.form?.provinciaHasta, false));
      }
    }

  }, [form.isFetching, isView, idArtProps])

  // Clear de redux
  useEffect(() => {
    return () => {
      dispatch(clearFormLocalidades());
    }
  }, [])

  // Verifica que se terminaron de cargar las provincias necesarias.
  useEffect(() => {

    const obtainedForm = form.data.form;
    const model = form.data.model;
    if (isView) {
      if (obtainedForm && model) {
        // Si cargo el form
        if (Object.keys(obtainedForm).length > 0 && Object.keys(model).length > 0) {
          let cargandoLocalidadesDesde = false;
          let cargandoLocalidadesHasta = false;
          if (obtainedForm.provinciaDesde && obtainedForm.provinciaDesde != "Capital Federal") {
            cargandoLocalidadesDesde = localidadDesde.data.length <= 0;
          }
          if (obtainedForm.provinciaHasta && obtainedForm.provinciaHasta != "Capital Federal") {
            cargandoLocalidadesHasta = localidadHasta.data.length <= 0;
          }

          // Caso provincia no valida para que no quede cargando infinitamente
          if (!model.fields?.provinciaDesde?.options.find((p) => (p.id == obtainedForm.provinciaDesde))) {
            cargandoLocalidadesDesde = false;
          }
          if (!model.fields?.provinciaHasta?.options.find((p) => (p.id == obtainedForm.provinciaHasta))) {
            cargandoLocalidadesHasta = false;
          }


          setloadingProvincias(cargandoLocalidadesDesde || cargandoLocalidadesHasta);
        }
      } else {
        setloadingProvincias(false);
      }

    }
  }, [localidadDesde, localidadHasta, form.isFetching])

  useEffect(() => {
    if (isView && !form.isFetching) {
      setFiles(form.data.form.adjuntos);
    }
  }, [form.isFetching]);

  useEffect(() => {
    if (!isLoading && !!setLoaded) {
      setLoaded(true);
    }
  }, [model.isFetching, saveForm.isFetching, loadingProvincias, fptModel]);

  useEffect(() => {
    if (idArt) {
      setIdArtProps(idArt);
    } else {
      setIdArtProps(accident.mlCloudCoreArtId);
    }
    if (siniestroProps) {
      setSiniestroState(siniestroProps);
    }
  }, [idArt, siniestroProps]);

  useEffect(() => {
    if (fpt.isOpen) {
      if (!Array.isArray(fpt.data)) {
        var fechaHora =
          fpt.data.fechaHoraNuevoControl ||
          fpt.data.fechaHora ||
          fpt.data.fechaHoraProximaConsulta;
        setDateWSplit(moment(fechaHora).format("DD/MM/YYYY HH:mm"));
      } else {
        setDateWSplit({
          primerTurno: moment(fpt.data[0].fechaHora).format("DD/MM/YYYY HH:mm"),
          ultimoTurno: moment(fpt.data[fpt.data.length - 1].fechaHora).format(
            "DD/MM/YYYY HH:mm",
          ),
        });
      }
    }
  }, [fpt.isOpen]);

  useEffect(() => {
    if (!model.isFetching && !isView) {
      if (!fpt.isOpen) {
        setFptModel({
          ...initialFptModel,
          fechaHoraSolicitud: new Date(),
        });
      } else if (dateWSplit !== null) {
        if (!dateWSplit.primerTurno) {
          if (!fpt.data.especialidadNombre) {
            var especialidad = fpt.data.appointment.especialidadNombre;
          } else {
            var especialidad = fpt.data.especialidadNombre;
          }
          setFptModel({
            ...initialFptModel,
            solicitadoPor: fpt.data.profesionalApellidoNombre,
            fechaHoraSolicitud: new Date(),
            observaciones:
              "Fechas turnos: " +
              dateWSplit +
              " | " +
              "Profesional: " +
              fpt.data.profesionalApellidoNombre +
              " | " +
              "Especialidad: " +
              especialidad,

            fechaHoraTurno:
              fpt.data.fechaHoraNuevoControl ||
              fpt.data.fechaHoraProximaConsulta ||
              fpt.data.fechaHora ||
              fpt.data[0].fechaHora,
          });
        } else {
          setFptModel({
            ...initialFptModel,
            solicitadoPor: fpt.data[0].profesionalApellidoNombre,
            fechaHoraSolicitud: new Date(),
            observaciones:
              "Fecha turnos: " +
              dateWSplit.primerTurno +
              " - " +
              dateWSplit.ultimoTurno +
              " | " +
              "Profesional: " +
              fpt.data[0].profesionalApellidoNombre +
              " | " +
              "Especialidad: " +
              fpt.data[0].especialidadNombre ||
              fpt.data.appointment.especialidadNombre ||
              fpt.data.especialidadNombre,

            fechaHoraTurno: moment(dateWSplit.primerTurno, "DD/MM/YYYY HH:mm"),
          });
        }
      }
    }
  }, [model.isFetching, dateWSplit]);

  // Usamos intial values para el form si viene con algun valor sin configurar
  useEffect(() => {


    if (isView && form.data.form && Object.keys(form.data.form).length > 0) {
      const valoresFormReemplazados = { ...form.data.form }

      Object.keys(form.data.form).forEach(k => {
        valoresFormReemplazados[k] = form.data.form[k] === null ? initialFptModel[k] : form.data.form[k]
      })

      setFptModel(valoresFormReemplazados);
    }
  }, [form.isFetching]);

  const handleSubmit = (form, idArt) => {
    const filesAux = [];
    files.forEach((e) =>
      filesAux.push({ ...e, nombre: e.filename || e.nombre, base64: e.value }),
    );
    var model = {
      ...form,
      numeroSiniestroPrestador: accident.idSiniestro || siniestroState,
      adjuntos: filesAux,
      fechaHoraSolicitud: convertDateTimeToNet(form.fechaHoraSolicitud),
      fechaHoraTurno: convertDateTimeToNet(form.fechaHoraTurno),
    };
    if (fromErroresConectividad) {
      model.numeroSiniestroPrestador = selectedDocument.siniestro;
    }
    if (dateWSplit) {
      //creo un array vacio para que el back reciba un array con un solo id y no el id directo, asi funciona el codigo del back sin modificaciones

      var arrayIdTurnosAux = [];
      if (postAppointment.data.idTurnoSiniestro) {
        arrayIdTurnosAux.push(postAppointment.data.idTurnoSiniestro);
      } else if (saveForm.data.idTurnosArray) {
        arrayIdTurnosAux = saveForm.data.idTurnosArray;
      } else if (postSession.turnosArray) {
        arrayIdTurnosAux = postSession.turnosArray;
      } else if (fpt.data.appointment) {
        arrayIdTurnosAux.push(fpt.data.appointment.idTurnoSiniestro);
      }
      model = {
        ...model,
        idTurnos: arrayIdTurnosAux,
      };
      //borro la data del redux que me trae el post segun sea session o unico turno
      dispatch(clearIdTurnos());
    }

    dispatch(postForm("fpt", model, idArt))
      .then(() => {
        if (fromErrorDialog) {
          dispatch(
            correctConnectivityErrorAction(
              selectedDocument.idRespError,
              accident.mlCloudCoreArtId,
            ),
          ).then((res) => {
            dispatch(
              getDocuments(accident.idSiniestro, accident.mlCloudCoreArtId),
            );
            dispatch(
              getConnectivityErrors(
                selectedDocument.nroTransaccion,
                accident.mlCloudCoreArtId,
              ),
            ).then((res) => handleClose(true, false));
          });
        } else {
          handleClose(true, false);
        }
        if (fromErroresConectividad) {
          dispatch(
            correctConnectivityErrorAction(
              selectedDocument.idRespError,
              accident.mlCloudCoreArtId,
            ),
          ).then((res) => {
            dispatch(getAllConnectivityErrors(time));
          });
        }
      })
      .catch(() => handleClose(true, true));
  };

  return (
    <div style={{ width: "100%" }}>
      {isLoading ? (
        <div style={{ marginTop: 100 }}>
          <CircularLoading />
        </div>
      ) : isView ? (
        <Fpt
          handleClose={handleClose}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleSubmit}
          model={form.data.model}
          fptModel={fptModel}
          roles={form.data.roles}
          selectedDocument={selectedDocument}
          idArt={idArtProps}
          fromErrorDialog={fromErrorDialog}
          fromErroresConectividad={fromErroresConectividad}
          isView={isView}
        />
      ) : (
        <Fpt
          handleClose={handleOpenConfirmDialog}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleSubmit}
          model={model.data}
          fptModel={fptModel}
          idArt={idArtProps}
          isView={isView}
          disableDateUpdate={fpt.isOpen}
        />
      )}
    </div>
  );
};

export default FptContainer;
